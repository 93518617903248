.experience__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container > div{
    background: #ADD8E6;
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    color: black;
}

/* .experience__container > div:hover {
    background: wheat;
    border-color: var(--color-primary-variant);
    cursor: pointer;
} */
.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
}

.experience__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
}

.experience__content i{
    font-size: 65px;
}

/* ================== TABLETS ========================== */
@media screen and (max-width: 1024px) {
    .experience__container{
        grid-template-columns: 1fr;
    }
    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    .experience__content{
        padding: 1rem;
    }
}

/* =================== MEDIA QUERIES (SMALL) ============= */

@media screen and (max-width: 600px) {
    .experience__container{
        gap: 1rem;
    }
    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
        margin: 0 auto;
    }
    .experience__content{
        padding: 1rem;
    }
}